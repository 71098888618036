<template>
  <div class="wallpaper-login">
    <v-container fluid>
      <v-layout row wrap>
        <!-- xs12 and sm12 to make it responsive = 12 columns on mobile and 6 columns from medium to XL layouts -->
        <v-flex xs12 sm12 md8>
          <div class="version">
            <h4>Postzegel & Stamcollector built by integratiepartners</h4>
            <p>
              Copyright 2023 © CRM version: 2.41.4
            </p>
          </div>
          <!-- Login form here -->
        </v-flex>
        <v-flex xs12 sm12 md4>
          <!-- artwork here -->
          <div class="sidebar">
            <div class="logincontainer">
              <div class="form-group mb-3" id="emailGroup">
                <label>Gebruikersnaam</label>
                <input
                  class="form-control"
                  placeholder="Gebruikersnaam"
                  name="username"
                  type="text"
                  v-model="email"
                />
              </div>

              <div class="form-group mb-3" id="passwordGroup">
                <label>Wachtwoord</label>
                <input
                  class="form-control"
                  placeholder="Wachtwoord"
                  name="password"
                  type="password"
                  v-model="password"
                />
              </div>

              <br />

              <button type="submit" class="btn btn-block login-button">
                <span class="signin" @click="userLogin()">Inloggen</span>
              </button>
              <div class="clearfix"></div>

              <br />
              <p>
                <a class="lost-pass-link" href="/" title="Forgot Password"
                  >Wachtwoord vergeten?</a
                >
              </p>
            </div>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { userLogin, resetPassword } from "../api/apiLogin.js";
export default {
  data: () => ({
    show3: false,

    email: "",
    password: "",
  }),
  props: {
    source: String,
  },
  methods: {
    goToContact() {
      this.$router.push("/Contact");
    },
    melding() {
      this.$swal.fire({
        icon: "error",
        title: "Oops...",
        text: "controleer of alle velden ingevuld!",
      });
    },
    userLogin() {
      userLogin.call(this, this.email, this.password);
    },

    // userLogin() {
    //   try {
    //     userLogin.call(this, this.email, this.password);
    //     this.$router.push("/Dashboard");
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
    resetPassword() {
      resetPassword.call(this, this.email);
    },
  },
};
</script>
<style scoped>
.logincontainer {
  margin-top: -150px;
  padding: 30px;
  position: absolute;
  top: 50%;
  width: 100%;
  z-index: 10;
}
.logincontainer button {
  background: #02192d;
  background-color: rgb(2, 25, 45);
  border: 0;
  border-radius: 2px;
  color: #eee !important;
  display: block;
  float: left;
  font-size: 11px;
  font-weight: 400;
  opacity: 0.8;
  outline: 0 !important;
  padding: 12px 20px;
  text-align: center;
  text-transform: uppercase;
  transition: width 0.3s ease;
  width: auto;
}
.sidebar {
  background: #fff;
  border-radius: 0;
  border-top: 5px solid #02192d;
  justify-content: center;
  min-height: 100vh;
  padding: 0;
  position: relative;
  z-index: 2;
}
.version p {
  color: #fff;
  font-size: 13px;
  font-weight: 300;
  left: 15px;
  margin-top: 0;
  max-width: 650px;
  opacity: 0.9;
  position: relative;
  top: -8px;
  z-index: 99;
}

.version h4 {
  color: #fff;
  display: inline-block;
  font-size: 20px;
  font-weight: 700;
  line-height: 45px;
  margin: 20px 0 0 14px;
  position: relative;
  text-transform: uppercase;
  top: 0;
  vertical-align: middle;
  z-index: 9999;
}
.version {
  position: absolute;
  bottom: 8px;
  left: 16px;
  font-size: 18px;
}
.v-application .rounded-bl-xl {
  border-bottom-left-radius: 300px !important;
}
.v-application .rounded-br-xl {
  border-bottom-right-radius: 300px !important;
}
.space {
  margin-bottom: 2px;
}

.wallpaper-login {
  animation-name: wallpaper-animation;
  animation-duration: 60s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  background-image: url("../../assets/LoginImages/1.jpg");
  background-size: cover;
  height: 100%;
  position: absolute;
  width: 100%;
}

@keyframes wallpaper-animation {
  0% {
    background-image: url("../../assets/LoginImages/1.jpg");
  }
  25% {
    background-image: url("../../assets/LoginImages/2.jpg");
  }
  50% {
    background-image: url("../../assets/LoginImages/3.jpg");
  }
  75% {
    background-image: url("../../assets/LoginImages/4.jpg");
  }
  100% {
    background-image: url("../../assets/LoginImages/5.jpg");
  }
}
</style>
